*,
::after,
::before {
  box-sizing: border-box;
}

:root {
    --orangePrimary: #F64C04;
}

body {
    scroll-behavior: smooth;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 !important;
    overflow: auto !important;
  }
  .dark{
    background-color: #050D15;
  }
  .dark .MuiTypography-bannerTitle{
    color:#fff;
  }
  .light .MuiTypography-bannerTitle{
    color:#000;
  }
  .dark .MuiTypography-bannerText{
    color:#fff9;
  }
  .light .MuiTypography-bannerText{
    color:#000;
  }
  .dark .MuiTypography-text{
    color:#fff9;
  }
  .light .MuiTypography-text{
    color:#000;
  }
  .dark .navbar-container{
    background-color:#050D15;
  }
  .light .navbar-container{
    background-color:#faf9f5;
  }
  .dark .MuiTypography-body1{
    color:#fff;
  }
  .dark .previousBox{
    background:linear-gradient(#14212E, #0E1924)
  }
  .light .MuiTypography-body1{
    color:#000;
  }
  .dark .pool-subtitle, .dark .dust-pool-root p, .dark .prevtitleText, .dark .snp-text-p-n{
    color:#fff;
  }
  .dark .dust-pool-textbox p:nth-child(2),.dark .dust-pool-textbox p:nth-child(3){
    color:#fff9 !important;
  }
  .dark .pool-ps-root div:nth-child(3) div p {
    color:#fff9;
  }
  .pool-ps-root div:nth-child(3) div p a {
    color: #F64C04;
    text-decoration: none;
  }
  .dark .tab-choose{
    color:#000;
    
  }
  .dark .terms-box{
    background: #050D15;
  }
  .dark .terms-box .scrollable-content h2 { 
    background: #050D15;
    color: #FFF
  }
  .dark .filter-text, .dark .filter-text span {
    color: #FFF9
  }
  .dark .explorerTitle {
    color: #FFF
  }
  .dark .tab-choose div{
    background-color: #000;
    color: #fff;
  
  }
  .dark .tab-choose div {
    border: 2px solid #fff!important;
  }
  .dark .tab-choose div:hover{
    background-color: #fff;
    color: #000;
  }
  .dark .tab-choose div:first-child {
    border-right: none!important;
  }
  .dark .tab-choose div:nth-child(2) {
    border-right: none!important;
  }
  .dark .tab-choose .active-tab{
    background-color: #fff;
    color: #000;
  }
  .dark .dpc-box, .dark .snp-root {
    background:linear-gradient(#14212E, #0E1924)
  }
  .dark .dust-pool-card{
    background:linear-gradient(#14212E, #0E1924)
  }
  .dark-close-button{
    background-color: #F64C04;
    padding: 5px;
    border-radius: 5px;
  }
  .light .MuiTypography-body1{
    color:#000;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .dust-pool-root p{
    /* margin-block-start: unset; */
    margin-block-end: 0.5em;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
  .toastContainer {
    z-index: 4000;
  }
  a.oc-link-break-all{
    word-break: break-all;
  }
  #txRotator {
    animation-name: playRotating;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .page-decoration{
    position: absolute;
    z-index: 0;
    max-height: 600px;
    top: -20%;
  }
  .orange-main-button{
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 5px;
    font-family: poppins-semibold;
    font-size: 14px;
    color: rgb(255, 105, 43);
    border: 2px solid rgb(255, 105, 43);
    padding: 5px 20px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    min-height: 45px;
    margin: 50px auto 0 auto;
  }
  .orange-main-button:hover{
    background-color: rgb(255, 105, 43);
    color: rgb(255, 255, 255);
  }
  
  @keyframes playRotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @font-face {
    font-family: 'bau';
    src: local('bau'), url(./assets/fonts/Bau-Regular.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'poppins';
    src: local('poppins'), url(./assets/fonts/Poppins.ttf) format('truetype');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'poppins-semibold';
    src: local('poppins-semibold'),
      url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'poppins-medium';
    src: local('poppins-medium'),
      url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'anton';
    src: local('anton'), url(./assets/fonts/Anton.ttf) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'cubano';
    src: local('cubano'), url(./assets/fonts/Cubano.ttf) format('truetype');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'citrusgothic';
    src: local('citrusgothic'),
      url(./assets/fonts/CitrusGothic-Regular.otf) format('truetype');
    font-display: swap;
  }
  /* 
  @font-face {
    font-family: 'citrusgothic-solid';
    src: local('citrusgothic-solid'), url(./assets/fonts/CitrusGothicSolid-Regular.otf) format('truetype');
    font-display: swap;
  } */
  